/* PrettyButton.css */
.pretty-button {
  background-color: #29AB87;
  color: #f0f0f0;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 5px; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: 1px 1px 1px lightslategray;
  user-select: none;
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
}

.pretty-button:hover {
  filter: brightness(110%);
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.4); 
}

.pretty-button:active {
  transform: scale(0.95); 
}

.pretty-button:focus {
  outline: none;
}

.pretty-button.shareButton {
  font-size: 0.8em;
  background-color: rgba(65, 65, 65, 0);
  text-shadow: none;
  border: 1px solid #7b7b7b;
}

.pretty-button.submitClueButton {
  /* padding: 0; */
  /* height: 2.5em; */
  width: 100%;
}

.pretty-button.passTurnButton {
  background-color: #ffa500;
  text-shadow: none;
  color: black;
  width: 100%;
}

.pretty-button.disabled {
  text-shadow: none;
  color: #696969;
  background-color: #c2c2c2;
  filter: opacity(75%);
}

.pretty-button.disabled:active {
  transform: none;
}

.pretty-button.disabled:hover {
  /* filter: brightness(100%); */
  box-shadow: none;
}
