/* PrettyChatbox.css */

.chatbox {
    padding: 10px;
    border-radius: 5px;
    background-color: #3a3a4f; /* Initial background color */
    font-family: 'Nunito Sans', sans-serif;
    transition: background-color 0.5s ease, border-color 0.5s ease;
    width: 100%;
    margin: 0 auto; /* Center the chatbox */
    box-sizing: border-box;
  }

  .chatBoxMessages {
    padding: 5px; /* Minimal padding */
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Stretch each message to fit content only */
    height: 100px; /* Limit height */
    overflow-y: auto; /* Enable vertical scrolling */
}

.chatBoxMessage {
    display: block;
    max-width: 80%;
    margin-bottom: 0; /* Remove margin between messages */
    text-align: left;
    font-size: 0.7em;
    word-wrap: break-word;
    word-break: break-word;
}


.chatBoxMessage.red.spymaster {
    color: #D83A2E;
}

.chatBoxMessage.blue.spymaster {
    color: #3175B7;
}

.chatBoxMessage.red.guesser {
    color: var(--lightred);
}

.chatBoxMessage.blue.guesser {
    color: var(--lightblue);
}


.chatbox input[type="text"] {
padding: 5px;
border: 2px solid transparent;
border-radius: 5px;
background-color: #3a3a4f;
color: #f0f0f0;
width: 100%;
box-sizing: border-box;
transition: background-color 0.5s ease, border-color 0.5s ease;
font-family: 'Nunito Sans', sans-serif;

}
  
.chatbox input[type="text"] {
  background-color: #4c4c64;
  margin-bottom: 1em;
}
  .chatbox input[type="text"]:focus {
    background-color: #4c4c64;
  }
  
  .chatbox input[type="text"]::placeholder {
    color: #a9a9a9;
  }
  
  .chatbox input[type="text"].flashing {
    border: 2px solid #ff6a6a;
    animation: flash 2s infinite alternate;
  }

  .toggle-chatbox {
    cursor: pointer;
    padding: 5px;
    margin: 5px auto;
}

.toggle-chatbox.new {
  color: #ffff89;
  animation: pulse 3s infinite ease-in-out; /* Pulses every 2 seconds */
}
  
  /* Flashing red border effect */
  @keyframes flash {
    0% {
      border-color: #ff6a6a;
    }
    100% {
      border-color: transparent;
    }
  }

  @keyframes pulse {
    0%, 100% {
        filter: brightness(100%); /* Normal brightness */
    }
    50% {
        filter: brightness(150%); /* Brighter at midpoint */
    }
}

/* Applies to WebKit browsers */
.chatBoxMessages::-webkit-scrollbar {
    width: 6px; /* Thin scrollbar */
}

.chatBoxMessages::-webkit-scrollbar-track {
    background: transparent; /* Fully transparent track */
}

.chatBoxMessages::-webkit-scrollbar-thumb {
    background-color: rgba(160, 160, 160, 0.8); /* Lighter gray with 80% transparency */
    border-radius: 10px; /* Rounded edges for a smoother look */
}

/* Optional: for Firefox */
.chatBoxMessages {
    scrollbar-width: thin;
    scrollbar-color: rgba(160, 160, 160, 0.8) transparent;
}
