.pretty-dropdown {
  text-align: center;
  width: 200%;
  border: none;
  border-radius: 5px;
  background-color: #3a3a4f;
  color: #f0f0f0;
  font-size: 1.5em;
  font-family: 'Nunito Sans', sans-serif;
  transition: background-color 0.5s ease;
  cursor: pointer;
  appearance: none;
}

  
  .pretty-dropdown:focus {
    background-color: #4c4c64; /* Background color change on focus */
    outline: none; /* Remove default focus outline */
  }
  
  .pretty-dropdown option {
    background-color: #3a3a4f; /* Keep options consistent with dropdown background */
    color: #f0f0f0; /* Options text color */
  }
  
  .pretty-dropdown option:hover {
    background-color: #4c4c64; /* Option background change on hover */
  }
  
  .pretty-dropdown.flashing {
    border: 2px solid #6affa3;
    animation: flashGood 2s infinite alternate; /* Flashing effect */
  }

  .pretty-dropdown.disabled {
    border: 2px solid transparent;
    animation: none;
  }



  /* Keyframes for flashing border effect */
@keyframes flashGood {
  0% {
    border-color: #6affa3;
  }
  100% {
    border-color: transparent;
  }
}
