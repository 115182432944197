.landingPage {
  text-align: center;
  padding: 20px;
}

.section {
  margin: 1em;
  display: inline-block; /* To contain the tooltip within the button area */
  width: 75%; /* The width of the horizontal line */
  margin: 20px auto; /* Center it horizontally and add margin */
}

/* Tooltip container */
.section[data-tooltip]:hover::before {
  content: attr(data-tooltip); /* Use the data-tooltip attribute content */
  position: absolute;
  bottom: 100%; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(18, 18, 18, 0.5);
  color: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.8rem;
  z-index: 10;
  opacity: 1;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

/* Tooltip arrow */
.section[data-tooltip]:hover::after {
  content: '';
  position: absolute;
  bottom: calc(100% - 5px); /* Arrow just below the tooltip box */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

/* Hidden tooltip by default */
.section[data-tooltip]::before, .section[data-tooltip]::after {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.separator {
  width: 75%; /* The width of the horizontal line */
  margin: 0.5em auto; /* Center it horizontally and add margin */
  border-top: 1px solid #ccc; /* Light gray color for the line */
}

.message {
  color: #ff6a6a; /* Light red color */
  text-align: center; /* Center the text */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures centering within the parent container */
  flex-direction: column; /* Stack content vertically if there's more than one element */
  margin: 1em;
}

.message.hidden {
  display: none;
}

.message.success {
  color:aquamarine
}

.test{
  background-color: #a72bea;
  color: #f0f0f0;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 5px; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: 1px 1px 1px lightslategray;
  user-select: none;
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
}