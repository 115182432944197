.headerText {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .headerImage {
    width: 300px; /* Adjust to your preferred size */
    cursor: pointer;
    display: block;
    margin: 0 auto; /* Centers the image */
  }
  
  .connectionMessage {
    color:aquamarine;
    text-align: center; /* Center the text */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensures centering within the parent container */
    flex-direction: column; /* Stack content vertically if there's more than one element */
    margin-top: 0.25em;
  }
  
  .connectionMessage.hidden {
    display: none;
  }
  
  .connectionMessage.error {
    color: #ff6a6a; /* Light red color */

  }