/* Add this to your NumpadInput.css or inline styles for NumpadInput component */

.clue-box-input {
  padding: 5px;
  border: 2px solid transparent; /* Initial border to reserve space */
  border-radius: 5px;
  background-color: #3a3a4f; /* Initial background color */
  color: #f0f0f0; /* Input text color */
  font-size: 1em;
  transition: background-color 0.5s ease, border-color 0.5s ease; /* Smooth transition for background and border color change */
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box; /* Ensure border doesn't affect element size */
  width: 85%;
  border: 2px solid transparent;
  animation: flashGood 2s infinite alternate; /* Flashing effect */
}

.clue-box-input:focus {
  background-color: #4c4c64; /* Change the background color when input is focused */
}

.clue-box-input::placeholder {
  color: #a9a9a9; /* Placeholder color */
}

/* Flashing red border effect */
.clue-box-input.redFlash {
  border: 2px solid #ff6a6a; /* Red border */
  animation: flash 2s infinite alternate; /* Flashing effect */
}

.clue-box-input.disabled {
  animation: none;
}


/* Keyframes for flashing border effect */
@keyframes flash {
  0% {
    border-color: #ff6a6a;
  }
  100% {
    border-color: transparent;
  }
}
