/* Add this to your NumpadInput.css or inline styles for NumpadInput component */
 
  .game-code-input {
    width: 15em;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #3a3a4f; /* Initial background color */
    color: #f0f0f0; /* Input text color */
    font-size: 1em;
    transition: background-color 0.5s ease; /* Smooth transition for background color change */
    font-family: 'Nunito Sans', sans-serif;
  }
  
  .game-code-input:focus {
    background-color: #4c4c64; /* Change the background color when input is focused */
  }
  
  .game-code-input::placeholder {
    color: #a9a9a9; /* Placeholder color */
  }
  