:root {
    --red: #D83A2E;
    --blue: #3175B7;
    --lightred: #ff7167;
    --lightblue: #46a2ff;
  }
  
  /* Main container for the quadrants */
  .quadrant-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    width: 100%; /* Responsive width */
    margin: 1em auto;
    border-radius: 0.5em;
  }
  
  /* Style for each quadrant */
  .quadrant {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10em; 
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 0.5em; 
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.5s ease;
    box-sizing: border-box;
    /* text-shadow: 0.5px 0.5px 0.5px rgb(103, 103, 103); */
    user-select: none;
  }
  
  /* Style for red quadrant */
  .quadrant.red {
    background-color: var(--red);
  }
  
  /* Style for blue quadrant */
  .quadrant.blue {
    background-color: var(--blue);
  }
  
  .quadrant:hover {
    filter: brightness(110%);
  }
  
  /* Disabled state for red quadrant */
  .quadrant.red.disabled {
    background-color: #ccc;
    /* cursor: not-allowed; */
    /* pointer-events: none; */
    border: 3px inset var(--red);
    color: var(--red);
  }
  
  /* Disabled state for blue quadrant */
  .quadrant.blue.disabled {
    background-color: #ccc;
    /* cursor: not-allowed;
    pointer-events: none; */
    border: 3px inset var(--blue);
    color: var(--blue);
  }

  .selectTeamRoleHeader,.lobbyPlayerLine, .lobbyPlayers {
    font-size: large;
    margin: 1em auto;
    text-align: center;
  }

  .lobbyPlayerLine.currentPlayer {
    color: rgb(255, 255, 172);
    
  }
  
  
  
  /* Add media query for mobile devices (iPhones) */
  @media (max-width: 600px) {
    .quadrant-container {
        grid-template-columns: 1fr 1fr; /* Stack quadrants vertically */
        gap: 0.5em;
        width: 90vw; /* Use viewport width for more dynamic sizing */
    }
  
    .quadrant {
        height: 30vw; /* Height based on viewport width for better scaling */
        font-size: 1em; /* Ensure text is readable */
    }
  
    .selectTeamRoleHeader,.lobbyPlayerLine, .lobbyPlayers{
        font-size: medium;
    }
  }
  