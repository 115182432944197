.lobbyHeader {
  font-size: xx-large;
  text-align: center;
}

.waitingTextLine, .buttonContainer, .shareContainer {
  font-size: large;
  text-align: center;
}

.buttonContainer {
  padding: 1em;
}

.shareContainer {
  /* height: 0vw; */
  margin-top: 1em;
}

.invalidLobby {
  color: #ff6a6a; /* Light red color */
  text-align: center; /* Center the text */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures centering within the parent container */
  flex-direction: column; /* Stack content vertically if there's more than one element */
  margin: 1em;
  font-size: xx-large;
}

.lobbyPage {
  text-align: center;
  padding: 20px;
}



/* Add media query for mobile devices (iPhones) */
@media (max-width: 600px) {
   .waitingTextLine, .shareContainer {
      font-size: medium;
  }
}
