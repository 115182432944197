.board {
  display: grid;
  grid-template-columns: repeat(5, 5em); /* 100px / 16 = 6.25em */
  grid-template-rows: repeat(5, 5em); /* 100px / 16 = 6.25em */
  gap: 1em; /* 25px / 16 = 1.5625em */
  justify-content: center;
  align-items: center;
  margin: 0.75em; /* Center the whole grid horizontally */
  padding: 0.5em;
  border-radius: 0.5em; /* 8px / 16 = 0.5em */
  background-color: rgb(240, 240, 240, 0.5);
}

.card {
  width: 100%; /* 100px / 16 = 6.25em */
  height: 100%; /* 100px / 16 = 6.25em */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d2d2d2;
  cursor: pointer;
  font-size: 12px; /* 12px / 16 = 0.75em */
  text-align: center;
  border-radius: 0.5em; /* 8px / 16 = 0.5em */
  /* border: slategray 2px solid; */
  transition: background-color 0.5s ease, transform 0.5s ease; /* Smooth background change and flip */
  transform-style: preserve-3d;
  position: relative;
  color: #282c34;
  text-shadow: 1px 1px 1px #f0f0f0;
  user-select: none;

}

.card:hover{
  filter: brightness(90%);
}

.card.disabled:hover {
  filter: none;
}

.card.blue:hover, .card.red:hover, .card.assassin:hover, .card.bystander:hover {
  filter: none;
}

.card.blue {
  background-color: #3175B7;
  border: none;
  color: #d2d2d2;
  text-shadow: 1px 1px 1px #282c34;
}

.card.red {
  background-color: #D83A2E;
  border: none;
  color: #d2d2d2;
  text-shadow: 1px 1px 1px #282c34;

}

.card.bystander {
  background-color: #dbbe88; 
  border: none;
  color: #282c34;
  text-shadow: none;
}

.card.assassin {
  background-color: black;
  color: #ffa500;
  border: none;
  text-shadow: none;
}

.boardKey {
  display: grid;
  grid-template-columns: repeat(5, 2em);
  grid-template-rows: repeat(5, 2em);
  gap: 0.25em;
  justify-content: center;
  align-items: center;
  justify-items: center; /* Center grid items horizontally */
  align-items: flex; /* Center grid items vertically */
  width: fit-content; /* Adjust to fit content */
  padding: 0.5em; /* Add padding for equal spacing inside */
  box-sizing: border-box; /* Include the border in the total size */
  background-color: black;
  border-radius: 0.5em;

}

.boardKey.redFirst {
  border: 5px solid #D83A2E;
}

.boardKey.blueFirst {
  border: 5px solid #3175B7;
}

.cardKey {
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  cursor: pointer;
  border: 2px solid black;
  text-align: center;
  border-radius: 0.5em;
  font-size: 1em; /* Ensure text size is manageable */
  line-height: 1em; /* Set line-height to match font size */
  padding: 0; /* Ensure no padding interferes */
}

.cardKey.blue {
  background-color: #3175B7;
  color: #46a2ff;
}

.cardKey.red {
  background-color: #D83A2E;
  color: #ff7167;
}

.cardKey.bystander {
  background-color: #E4D3B4; 
}

.cardKey.assassin {
  background-color: black;
  color: #ffa500;
}

.keyContainer {
  display: flex;
  justify-content: center; /* Centers items horizontally */
}

.boardContainer {
  display: flex;
  justify-content: center; /* Horizontally center the board */
  align-items: center; /* Vertically center the board (if needed) */
  width: 100%; /* Ensure it takes up the full width */
}

.gamePage {
  text-align: center;
  padding: 20px;
}

.loadingBoard {
  text-align: center; /* Center the text */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures centering within the parent container */
  flex-direction: column; /* Stack content vertically if there's more than one element */
  margin: 1em;
  font-size: xx-large;
}

.clueContainer {
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: flex-start; /* Horizontally align the items to the left */
  width: 100%;
  margin-bottom: 1em;
  height: 1.5em;
  margin-top: 1.2em;
}

.clueContainer > * {
  width: 80%; /* First column - 80% */
}

.clueContainer > *:nth-child(2) {
  width: 20%; /* Second column - 20% */
}

.clueContainer > :first-child {
  text-align: left; /* Left align the content of the first child */
}

.clueContainer > :nth-child(2) {
  text-align: center; /* Left align the content of the first child */
}

.currentClue.hidden {
  font-size: large;
  text-align: center;
  /* visibility: hidden; */
  /* border: 2px solid #f0f0f0; */
}

.currentClue.red {
  color: #D83A2E;
  visibility: visible;
}

.currentClue.blue {
  color: #3175B7;
  visibility: visible;
}

.remainingCards.red {
  color: #D83A2E;
}

.remainingCards.blue {
  color: #3175B7;
}

.myTeamInfo {
  font-size: small;
  text-align: center;
}

.myTeamInfo.red {
  color: #D83A2E
}

.myTeamInfo.blue {
  color: #3175B7;
}

.teamInfoContainer {
  margin-top: 1em;
}

.winner.red {
  color: #D83A2E;
  visibility: visible;
}

.winner.blue {
  color: #3175B7;
  visibility: visible;
}

.postGameGuesser {
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: center; /* Centers children horizontally */
  justify-content: center; /* Centers children vertically */
}

.postGameGuesser {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.postGameGuesser > *:first-child {
  width: 100%; /* Make the first child take up the full width */
}

.loadingBoard.error {
  color: #ff6a6a;
} 

.newGameButtonContainer {
  margin-bottom: 1em;
}




/* Responsive styling for mobile devices */
@media screen and (max-width: 768px) {
  .board {
    grid-template-columns: repeat(5, 5em);
    grid-template-rows: repeat(5, 5em);
    gap: 0.5em; /* Adjust gap for smaller screens */
  }

  .card {
    width: 100%; /* Inherit the width of the grid cell */
    height: 100%; /* Inherit the height of the grid cell */
    font-size: 0.4em;
  }
}

@media screen and (max-width: 480px) {
  .board {
    grid-template-columns: repeat(5, 4em); /* Smaller grid cells */
    grid-template-rows: repeat(5, 4em); 
    gap: 0.5em; /* Adjust gap for smaller screens */
  }

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Inherit the width of the grid cell */
    height: 100%; /* Inherit the height of the grid cell */
    font-size: 0.4em; /* Adjust font size for mobile */
  }
}